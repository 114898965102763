.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide img {
    width: auto;
    height: 400px;

    object-fit: contain;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}