            /* Variables */

 
                 
            :root  {
              --black   :      rgba( 0 , 0 , 0 , 1 )  ;
              --teal-blue   :      rgba( 25 , 181 , 188 , 1 )  ;
              --mine-shaft   :      rgba( 51 , 51 , 51 , 1 )  ;
              --onahau   :      rgba( 199 , 255 , 255 , 1 )  ;
              --geraldine   :      rgba( 254 , 130 , 137 , 1 )  ;
              --white   :      rgba( 255 , 255 , 255 , 1 )  ;
            
              --font-size-s   : 12px ;
              --font-size-m   : 14px ;
              --font-size-l   : 16px ;
              --font-size-xl   : 20px ;
              --font-size-xxl   : 32px ;
              --font-size-xxxl   : 44px ;
            
              --font-family-noto_sans_thai   : "Noto Sans Thai" ;
              --font-family-ntr   : "NTR" ;
              --font-family-playfair_display   : "Playfair Display" ;
              --font-family-poppins   : "Poppins" ;
            }
            
            /* Classes */
            
              
                             
                      .notosansthai-bold-onahau-14px    {
              color : var( --onahau   ) ;
              font-family : var( --font-family-noto_sans_thai   ) ;
              font-size : var( --font-size-m   ) ;
              font-weight : 700 ;
              font-style : normal;
            }
              
                             
                      .poppins-normal-black-16px    {
              color : var( --black   ) ;
              font-family : var( --font-family-poppins   ) ;
              font-size : var( --font-size-l   ) ;
              font-weight : 400 ;
              font-style : normal;
            }
              
                             
                      .poppins-normal-mine-shaft-14px    {
              color : var( --mine-shaft   ) ;
              font-family : var( --font-family-poppins   ) ;
              font-size : var( --font-size-m   ) ;
              font-weight : 400 ;
              font-style : normal;
            }
             
             
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

@import  url( "https://fonts.googleapis.com/css?family=Playfair+Display:700|Poppins:400,700|NTR:400|Noto+Sans+Thai:700" )  ; 

.valign-text-middle  {
 display : flex;
 flex-direction : column;
 justify-content : center;
}

.b_es  {
 align-items : center;
 background-color :     #ff3e49 ;
 border-radius : 10px ;
 display : flex;
 height : 26px ;
 justify-content : center;
 padding : 10px 5px ;
 position : relative;
 width : 58px ;
}

.button  {
 letter-spacing : 0 ;
 line-height : 16.8px ;
 margin-bottom : -4.5px ;
 margin-left : -3.5px ;
 margin-right : -3.5px ;
 margin-top : -6.5px ;
 position : relative;
 text-align : center;
 white-space : nowrap;
 width : fit-content;
}

.b_es-1  {
 align-items : center;
 background-color : var( --geraldine ) ;
 border-radius : 10px ;
 display : flex;
 height : 26px ;
 justify-content : center;
 padding : 10px 5px ;
 position : relative;
 width : 54px ;
}

.button-1  {
 letter-spacing : 0 ;
 line-height : 16.8px ;
 margin-bottom : -4.5px ;
 margin-top : -6.5px ;
 position : relative;
 text-align : center;
 white-space : nowrap;
 width : fit-content;
}

.b_es-1 .button-2  {
 height : 29px ;
 width : fit-content;
}

.b_es-1 .button-2 .button-1  {
 margin-bottom : -3px ;
 margin-top : -5px ;
}

.header_top  {
 align-items : center;
 display : flex;
 justify-content : space-between;
 padding : 10px ;
 position : relative;
 width : 1234px ;
}

.logo-container  {
 height : 49px ;
 min-width : 241px ;
 position : relative;
}

.title  {
 color : var( --black ) ;
 font-family : var( --font-family-ntr ) ;
 font-size : var( --font-size-xxl ) ;
 font-weight : 400 ;
 height : 17px ;
 left : 19px ;
 letter-spacing : 0 ;
 line-height : 16.8px ;
 position : absolute;
 text-align : center;
 top : 15px ;
 white-space : nowrap;
}

.right-bar  {
 align-items : flex-end;
 display : flex;
 flex-direction : column;
 gap : 12px ;
 padding : 0px 5px ;
 position : relative;
 width : fit-content;
}

.lang_bar  {
 align-items : center;
 display : flex;
 gap : 10px ;
 height : 32px ;
 padding : 0px 20px ;
 position : relative;
 width : fit-content;
}

.line-1  {
 align-self : stretch;
 height : 1px ;
 min-width : 433.09px ;
 object-fit : cover;
 position : relative;
}

.ul  {
 align-items : center;
 display : flex;
 gap : 39px ;
 padding : 2px 0px ;
 position : relative;
 width : fit-content;
}

.regstrate  {
 color : var( --white ) ;
 font-family : var( --font-family-poppins ) ;
 font-size : var( --font-size-s ) ;
 font-weight : 700 ;
 letter-spacing : 0 ;
 line-height : 12px ;
 position : relative;
 white-space : nowrap;
 width : fit-content;
}

.entra  {
 color : var( --mine-shaft ) ;
 font-family : var( --font-family-poppins ) ;
 font-size : var( --font-size-s ) ;
 font-weight : 700 ;
 letter-spacing : 0 ;
 line-height : 16.8px ;
 position : relative;
 white-space : nowrap;
 width : fit-content;
}

.acceso-doctores  {
 color : var( --teal-blue ) ;
 font-family : var( --font-family-poppins ) ;
 font-size : var( --font-size-s ) ;
 font-weight : 700 ;
 height : 17px ;
 letter-spacing : 0 ;
 line-height : 16.8px ;
 position : relative;
 white-space : nowrap;
 width : 121.09px ;
}

/* styles.css */
strong {
  font-weight: bold;
  color: #07C4D9; /* Puedes ajustar el color según tus preferencias */
  /* Otros estilos aquí... */
}